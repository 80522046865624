<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn v-show="vertitulo" text>
        <span class="mr-2">{{ titulo }}</span>
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="puntitos" icon color="withe" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="salir">Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    nombre: "",
    apellido: "",
    empresa: "",
    titulo: "",
    puntitos: false,
    vertitulo: false,
  }),

  mounted() {
    this.nombre = localStorage.getItem("nombre");
    this.apellido = localStorage.getItem("apellido");
    this.empresa = localStorage.getItem("empresa");

    if (this.nombre == "" || this.nombre == null) {
      this.vertitulo = false;
      this.titulo = "Ingresar";
    } else {
      this.titulo = this.nombre + " " + this.apellido;
      this.puntitos = true;
      this.vertitulo = true;
    }
  },
  methods: {
    salir: function () {
      localStorage.setItem("usuario", "");
      localStorage.setItem("nombre", "");
      localStorage.setItem("apellido", "");
      localStorage.setItem("empresa", "");
      location.href = "/";
    },
  },
};
</script>
