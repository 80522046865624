import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1976d2',
                secondary: '#505050',
                accent: '#0056ff',
                error: '#a5023f',
            },
        },
    },
});