<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-img
          :src="require('../assets/startingpoint_logo.png')"
          class="my-3"
          contain
          height="100"
        />
        <h1 class="display-2 font-weight-bold mb-3">Ingreso al sistema</h1>

        <p class="subheading font-weight-regular">
          Por favor ingrese su usuario y contraseña para
          <br />entrar al sistema
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Login</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        prepend-icon="mdi-account"
                        name="login"
                        label="Usuario"
                        type="text"
                        v-model="username"
                      ></v-text-field>
                      <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="Password"
                        type="password"
                        v-model="password"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="login()" color="primary" to="/"
                      >Entrar</v-btn
                    >
                  </v-card-actions>
                </v-card>
                <v-alert
                  v-model="login_incorrecto"
                  type="error"
                  dark
                  transition="slide-y-transition"
                  >Usuario o password incorrecto.
                </v-alert>
                <v-alert
                  v-model="login_correcto"
                  type="success"
                  dark
                  transition="slide-y-transition"
                  >Ingresando al sistema.
                </v-alert>
                <v-alert
                  v-model="login_vacio"
                  type="warning"
                  dark
                  transition="slide-y-transition"
                  >Debe ingresar un usuario y clave.
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "HelloWorld",

  data: () => ({
    login_incorrecto: false,
    login_correcto: false,
    login_vacio: false,
    username: "",
    password: "",
    nombre: "",
    apellido: "",
    empresa: "",
    titulo: "",
  }),

  mounted() {
    if (this.nombre == "") {
      this.titulo = "";
    } else {
      this.titulo = this.nombre + " " + this.apellido;
    }

    this.$watchLocation({
      enableHighAccuracy: true,
    }).then((coordinates) => {
      console.log(coordinates);
      this.cordenadas = coordinates;
    });
  },
  methods: {
    login: function () {
      if (this.username != "" && this.password != "") {
        axios
          .post(this.$localhost + "/chequea-login.php", {
            request: 1,
            username: this.username,
            password: this.password,
          })

          .then((response) => {
            if (response.data[0].status == 1) {
              this.login_correcto = true;
              setTimeout(() => {
                this.login_correcto = false;

                this.nombre = response.data[0].nombre;
                this.apellido = response.data[0].apellido;
                this.empresa = response.data[0].empresa;

                localStorage.setItem("usuario", this.username);
                localStorage.setItem("nombre", this.nombre);
                localStorage.setItem("apellido", this.apellido);
                localStorage.setItem("empresa", this.empresa);

                //this.$router.push({ path: '/pantalla1' });
                location.href = "/pantalla1";
              }, 1000);
            } else {
              this.login_incorrecto = true;
              setTimeout(() => {
                this.login_incorrecto = false;
              }, 1000);
            }
          })

          .catch((error) => {
            console.log(error);
          });
      } else {
        this.login_vacio = true;
        setTimeout(() => {
          this.login_vacio = false;
        }, 1000);
      }
    },
  },
};
</script>
