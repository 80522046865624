<template>
  <Inicio />
</template>

<script>
  import Inicio from '../components/Inicio'

  export default {
    name: 'Home',

    components: {
      Inicio,
    },
  }
</script>
